import { useEffect, useState } from "react";
import { AuthState, authStore } from "../../../features/authenticate/AuthStore";
import { useCreateMutation, useUpdateMutation } from "../../../features/api/Api";
import { generateUserId, generateUUID, isExist } from "../../../utils/methods";
import { useLocales } from "../../../hooks";

declare global {
    interface Window {
        bundleMobile: any;
    }
}

const checkLocal = () => {
    const authState = localStorage.getItem("auth");

    if (authState) {
        const auth = JSON.parse(authState);

        if (auth.state && auth.state.bundleId && auth.state.deviceId) {
            return {deviceId: auth.state.deviceId, bundleId: auth.state.bundleId};
        }
    }
    return null;
}

const WordleScreenInitialHandler = () => {
    const { translate }: any = useLocales();
    const authState: AuthState = authStore();
    const [create] = useCreateMutation();
    const [update] = useUpdateMutation();

    useEffect(() => {
        if (window.bundleMobile) {
            const handleUserRegistration = async () => {
                const localState = checkLocal();

                if (localState && window.bundleMobile.deviceId === localState.deviceId && window.bundleMobile.bundleId === localState.bundleId) {
                    return;
                }

                let isVisitedStr: string | null = localStorage.getItem("isVisited");

                if (!isVisitedStr) {
                    isVisitedStr = "false";
                    localStorage.setItem("isVisited", "true");
                }

                const isVisited: boolean = isVisitedStr === "true";

                if (!isVisited) {
                    await create({
                        resource: "dailywords/register-user",
                        params: {
                            userId: isExist(window.bundleMobile.bundleId) ? window.bundleMobile.bundleId : window.bundleMobile.anonymousUserId,
                            deviceToken: window.bundleMobile.deviceId,
                        },
                    });
                }

                if (window.bundleMobile.accessToken && window.bundleMobile.bundleId) {
                    authState.useLogin(window.bundleMobile.accessToken, window.bundleMobile.bundleId, window.bundleMobile.deviceId);

                    await update({
                        resource: "dailywords/check-devicetoken",
                        headers: {
                            userId: window.bundleMobile.bundleId,
                            deviceToken: window.bundleMobile.deviceId,
                        },
                    });
                } else authState.useAnonymousLogin(window.bundleMobile.anonymousUserId, window.bundleMobile.deviceId);

                await create({
                    resource: "dailywords/register-user",
                    params: {
                        userId: isExist(window.bundleMobile.bundleId) ? window.bundleMobile.bundleId : window.bundleMobile.anonymousUserId,
                        deviceToken: window.bundleMobile.deviceId,
                    },
                })
                    .unwrap()
                    .then((res: any) => {
                        const userName: string = window.bundleMobile.accessToken ? res.data.userName : translate("anonymous");

                        authState.setUserInfo(userName, res.data.image, res.data.isPremium);
                    });

            };

            handleUserRegistration();
        }

        else if (!window.bundleMobile) {
          const handleUserRegistration = async () => {

            const localState = checkLocal();

            if (localState) {
                return; 
            }
            const deviceToken = generateUUID();
    
            const userId = generateUserId();
    
            await create({
              resource: "dailywords/register-user",
              params: {
                userId: userId,
                deviceToken,
              },
              headers: {
                "X-Anonymous-User": "anonymousUser",
              },
            });
            authState.useAnonymousLogin(userId, deviceToken);
          };
    
          handleUserRegistration();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.bundleMobile]);

    return null;
};

export default WordleScreenInitialHandler;
