import Header from "./Header";
import { ThemeMode } from "../../utils/enums";
import { useAppSelector } from "../../app/hooks";
import { FC, ReactNode, useEffect, useState } from "react";
import { Container, Divider, Hidden } from "@mui/material";
import { selectThemeMode } from "../../features/theme/ThemeSlice";

type IProps = {
  children: ReactNode;
  isCompleteSplashScreen: boolean;
};

const isIOS = (): boolean => {
  if (window.webkit) {
    return true;
  }
  return false;
};

const Layout: FC<IProps> = (props) => {
  const { children, isCompleteSplashScreen } = props;
  const mode: string = useAppSelector(selectThemeMode);
  const [pageHeight, setPageHeight] = useState<number>(0);
  const [isIOSDevice, setIsIOSDevice] = useState<boolean>(false);

  useEffect(() => {
    const root: HTMLElement = document.getElementById("root") as HTMLElement;

    root.style.background = mode === ThemeMode.dark ? "#000000" : "#f4f5f6";
  }, [mode, isCompleteSplashScreen]);

  useEffect(() => {
    const handleResize = () => setPageHeight(window.innerHeight);

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsIOSDevice(isIOS());
  }, []);

  return (
    <Container
      id="main"
      className={mode}
      maxWidth={false}
      sx={{ height: `${pageHeight}px` }}
    >
      <Header
        isCompleteSplashScreen={isCompleteSplashScreen}
        isIosDevice={isIOSDevice}
      />

      <Hidden lgDown>
        <Divider orientation="horizontal" flexItem sx={{ marginTop: "6px" }} />
      </Hidden>

      <Container maxWidth="sm" id="main-content">
        {children}
      </Container>
    </Container>
  );
};

export default Layout;
